<div cdkDropListGroup>
  <div class="relationships-container">
    <h2>{{ 'manage.relationships.selected-list.title' | translate }}</h2>
    <ds-loading *ngIf="(isLoading | async)"></ds-loading>
    <ng-container *ngIf="!(this.isLoading | async)">
      <div *ngIf="(filteredRelationships && filteredRelationships.length > 0) ; else noData"
        cdkDropList
        [cdkDropListData]="filteredRelationships"
        class="relationships-list"
        (cdkDropListDropped)="drop($event)">

        <div *ngFor="let relationship of filteredRelationships; let last = last" class="relationships-sort-list"
             [class.border-bottom]="!last" cdkDrag [cdkDragDisabled]="pendingChanges">
          <ds-relationships-items-list-preview
                  (deleteRelationship)="deleteRelationship.emit($event)"
                  [pendingChanges]="pendingChanges"
                  [viewConfig]="'relationship-sort-list'"
                  [customData]="{ relationship: relationship }"
                  [item]="(relationship.leftItem | async)?.payload"></ds-relationships-items-list-preview>
        </div>
      </div>

      <ng-template #noData>
         <ds-alert [type]="'alert-info'">{{ 'manage.relationships.no.relationships.found' | translate }}</ds-alert>
      </ng-template>
    </ng-container>

  </div>

</div>
