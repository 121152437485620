<div class="row d-flex align-items-center"
     *ngIf="!(isHidden$ | async) &&
      canLoadScript &&
      !failed &&
      (remark | dsListMetricProps: 'data-badge-enabled':isListElement == true)"
>
  <div class="col-5 text-left">
    <div
      #metricChild
      class="__dimensions_badge_embed__"
      [attr.data-hide-zero-citations]="visibleWithoutData ? false : (remark | dsListMetricProps: 'data-hide-zero-citations':isListElement)"
      [attr.data-pmid]="
      (remark | dsListMetricProps: 'data-doi':isListElement)
          ? null
          : (remark | dsListMetricProps: 'data-pmid':isListElement)
      "
      [attr.data-doi]="remark | dsListMetricProps: 'data-doi':isListElement"
      [attr.data-style]="remark | dsListMetricProps: 'data-style':isListElement"
      [attr.data-legend]="remark | dsListMetricProps: 'data-legend':isListElement"
    ></div>
  </div>
  <div class="col-7" *ngIf="!hideLabel">
    <div class="font-weight-bold text-capitalize">
      {{ metric.metricType | translate }}
    </div>
  </div>
</div>
<div class="row d-flex align-items-center justify-content-center m-2"  *ngIf="!canLoadScript && !isListElement">
  <div>
    {{ "third-party-metrics-cookies.message" | translate: {metricType: metric.metricType | titlecase} }}
    <div role="button" class="btn-link" (click)="requestSettingsConsent.emit(true)">{{"third-party-metrics-cookies.consent-settings" | translate}}</div>
  </div>
</div>
